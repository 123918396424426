<template>
    <div style="background-image: url('/build/assets/img/theme/home-bg.jpg'); height: auto; background-size: cover; padding-bottom: 80px">
        <div class="px-3 mx-auto p-0 d-flex main-image-wrapper mt-2 pt-1">
            <b-carousel
                id="carousel-1"
                :interval="3000"
                controls
                fade
                style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%;"
            >
                <b-carousel-slide
                    img-src="/build/assets/img/theme/main0.jpg"
                ></b-carousel-slide>

                <b-carousel-slide
                    img-src="/build/assets/img/theme/main1.png"
                ></b-carousel-slide>

                <b-carousel-slide
                    img-src="/build/assets/img/theme/main2.png"
                ></b-carousel-slide>
                <!-- <b-carousel-slide
                    img-src="/build/assets/img/theme/main3.png"
                ></b-carousel-slide> -->
                <!-- <b-carousel-slide
                    img-src="/build/assets/img/theme/main4.png"
                ></b-carousel-slide> -->
            </b-carousel>
        </div>

        <div class="account-container-wrapper py-3 my-3">
            <div class="top-wrapper px-3">
                <div class="top-left">
                    <b>
                        <i class="fas fa-wallet my-auto mr-3 text-red"></i>
                    </b>
                    <div class="my-auto text-dark">
                        <b>
                            {{
                                $store.state.language === "en"
                                    ? "Main Wallet :"
                                    : "ပိုက်ဆံအိတ် :"
                            }}
                        </b>
                    </div>
                </div>
                <div class="top-right">
                    <div class="my-auto text-darker">
                        <b>{{
                            Number($store.state.amount).toLocaleString()
                        }}</b>
                        {{ $store.state.language === "en" ? "MMK" : "ကျပ်" }}
                    </div>
                </div>
            </div>
            <!-- <hr /> -->
            <!-- <div class="bottom-wrapper px-3">
                <div class="bottom-left">
                    <i class="fas fa-user my-auto mr-3"></i>
                    <div class="my-auto">
                        {{
                            $store.state.language === "en"
                                ? "Phone Number :"
                                : "ဖုန်းနံပါတ် :"
                        }}
                    </div>
                </div>
                <div class="bottom-right">
                    <div class="my-auto">{{ $auth.user().phone }}</div>
                </div>
            </div> -->
        </div>

        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="websiteInfo[0]?.noticeText"
            v-if="!threedData.embedded_link && !threedData.isLive"
        />

        <div class="justify-content-center cards-container-mobile">
            <!-- <div
                class="col-xl-6 col-6 home-cards pr-2"
                @click="redirectRoute('user/2d/home')"
            >
                <div class="card">
                    <img
                        src="/build/assets/img/theme/3d.jpg"
                        alt=""
                        style="width: 100%; height: 100%; border-radius: 5px"
                    />
                </div>
            </div>

            <div
                class="col-xl-6 col-6 home-cards pl-2"
                @click="redirectRoute('user/3d/home')"
            >
                <div class="card">
                    <img
                        src="/build/assets/img/theme/2d.jpg"
                        alt=""
                        style="width: 100%; height: 100%; border-radius: 5px"
                    />
                </div>
            </div> -->
            <div
                class="col-xl-6 col-6 home-cards pr-2"
                @click="redirectRoute('maung-fb')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px;">
                    <div>
                        <img
                            src="/build/assets/img/theme/maung.png"
                            alt=""
                            style="width: 100px; height: 70px;"
                        />
                    </div>
                    <!-- <div>
                        <h5 class="text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Maung"
                                    : "မောင်း"
                            }}
                        </h5>
                    </div> -->
                </div>
            </div>
            <div
                class="col-xl-6 col-6 home-cards pl-2"
                @click="redirectRoute('body-fb')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px">
                    <div>
                        <img
                            src="/build/assets/img/theme/body.png"
                            alt=""
                            style="width: 100px; height: 70px;"
                        />
                    </div>
                    <!-- <div>
                        <h5 class="text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Body"
                                    : "ဘော်ဒီ"
                            }}
                        </h5>
                    </div> -->
                </div>
            </div>

            <div
                class="col-xl-6 col-6 home-cards pr-2"
                @click="redirectRoute('betslip-history')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px">
                    <div>
                        <img
                            src="/build/assets/img/theme/current_voucher.png"
                            alt=""
                            style="width: 120px; height: 80px;"
                        />
                    </div>
                    <!-- <div>
                        <h5 class="text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Voucher List"
                                    : "ဘောက်ချာစာရင်းများ"
                            }}
                        </h5>
                    </div> -->
                </div>
            </div>
            <div
                class="col-xl-6 col-6 home-cards pl-2"
                @click="redirectRoute('history')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px">
                    <div>
                        <img
                            src="/build/assets/img/theme/old_vouchers.png"
                            alt=""
                            style="width: 120px; height: 80px;"
                        />
                    </div>
                    <!-- <div>
                        <h5 class="text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Transactions"
                                    : "ငွေစာရင်းများ"
                            }}
                        </h5>
                    </div> -->
                </div>
            </div>

            <!-- <div
                class="col-xl-6 col-6 home-cards pl-2"
                @click="redirectRoute('tip')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px">
                    <div>
                        <img
                            src="/build/assets/img/theme/tip.jpeg"
                            alt=""
                            style="width: 50px; height: 50px;"
                        />
                    </div>
                    <div>
                        <h5 class="text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Tip"
                                    : "Tip"
                            }}
                        </h5>
                    </div>
                </div>
            </div> -->

            <div
                class="col-xl-6 col-6 home-cards pl-2"
                @click="redirectRoute('Live')"
            >
                <div class="card d-flex justify-content-center align-items-center" style="width: 100%; height: 130px; border-radius: 5px">
                    <div>
                        <img
                            src="/build/assets/img/theme/live.png"
                            alt=""
                            style="width: 150px; height: 100px;"
                        />
                    </div>
                    <!-- <div>
                        <h5>Transactions Report</h5>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { NoticeBar, Dialog } from 'vant';

export default {
  components: { NoticeBar },
  data() {
    return {
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
    };
  },
  methods: {
    async redirectRoute(path) {
      try {
        if (path === 'tip') {
          const text = this.$store.state.language === 'en'
            ? 'This feature is not support yet.'
            : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Okay'
                      : 'Okay',
          }).then(() => {
          // on close
          });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/auth/login');
    }
    this.fetchWebsiteData();
    // this.fetchTwoDdata();
    // this.fetchThreedDdata();
    // this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.card {
    cursor: pointer;        
    background: rgb(255, 255, 255, 0.35) !important;    
}
.main-image-wrapper img {
    width: 100%;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: white !important;
    background: black !important;
    border-top: 1px solid #FF9130;
    border-bottom: 1px solid #FF9130;
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;        
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    .middle-container {
        padding-top: 0.1rem !important;
    }
    hr {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 35px;
        z-index: 1;
        font-size: 12px;
        color: #000;
        background: #FECDA6;
    }
    .live-number {
        font-weight: bold;
        font-size: 20px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 37px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;        
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .account-container-wrapper {
        width: auto;
        height: auto;
        border-radius: 3px;
        padding: 4px;
        margin: 10px;
        color: #fff;
        background: #FF9130;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .top-wrapper,
    .bottom-wrapper {
        padding: 10px;
        width: 100%;
        display: flex;
    }
    .top-right,
    .bottom-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
    }
    .top-left,
    .bottom-left {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: start;
    }
}
</style>
