export const routes = [
  {
    path: "/data-table",
    component: render("frontend/DataTable"),
  },
  {
    path: "/download",
    component: render("frontend/Download"),
  },
  {
    path: "/",
    component: render("frontend/Index"),

    children: [
      {
        path: "",
        component: render("frontend/Home"),
        // meta: { auth: true },
      },
      {
        path: "home",
        component: render("frontend/Home"),
        // meta: { auth: true },
      },
      {
        path: "rule",
        component: render("frontend/auth/Rule"),
        // meta: { auth: true },
      },
      {
        path: "auth/login",
        component: render("frontend/auth/Login"),
      },
      {
        path: "betslip-history",
        component: render("frontend/BetslipHistory"),
        // meta: { auth: true },
      },
      {
        path: "history",
        component: render("frontend/History"),
        // meta: { auth: true },
      },
      {
        path: "maung-fb",
        component: render("frontend/MaungFB"),
        // meta: { auth: true },
      },
      {
        path: "body-fb",
        component: render("frontend/BodyFB"),
        // meta: { auth: true },
      },
      {
        path: "setting",
        component: render("frontend/Setting"),
        // meta: { auth: true },
      },
      {
        path: "contact-us",
        component: render("frontend/ContactUs"),
        // meta: { auth: true },
      },
      {
        path: "live",
        component: render("frontend/Live"),
        // meta: { auth: true },
      },
      {
        path: "video-links",
        component: render("frontend/VideoLink"),
        // meta: { auth: true },
      },
      {
        path: "download",
        component: render("frontend/Download"),
      },
    ],
  },
];

function render(template) {
  return require(`@/pages/${template}.vue`).default;
}
