<template>
    <div>
        <div :style="itemsCart.length > 0 ? 'margin-bottom: 7rem !important' : ''">
            <div class="container d-flex justify-content-center mb-3 mt-5">
                <div class="mr-2 pt-1" style="height: 25px; border-radius: 10px; background: red;" v-if="itemsCart.length > 0">
                    <b class="text-white px-3">Today Matches</b>
                </div>
                <div class="text-danger text-lg" v-else>
                    There is no live matches!
                </div>
            </div>
            <div class="container">
                <div class="card work" v-for="(item, itemKey) in itemsCart" :key="itemKey" @click="redirectRoute('video-Links', {index: itemKey})">
                    <div class="d-flex justify-content-around p-3">
                        <div class="d-flex flex-column align-items-center" style="width: 130px;">
                            <img :src="item.home_team_logo">
                            <h6 class="pt-2" style="font-weight: bold;">{{ item.home_team_name }}</h6>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="mt-4 mb-1">
                                <p class="text-white pt-1 my-auto" id="pTag">Vs</p>
                            </div>
                            <div class="mx-auto mt-2" v-if="item.match_status.toLowerCase() === 'live'">
                                <p class="badge badge-danger pt-2 my-auto live">LIVE</p>
                            </div>
                            <div class="mx-auto mt-2" v-if="item.match_status.toLowerCase() !== 'live'">
                                <p class="badge badge-info pt-2 my-auto coming">Soon</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center" style="width: 130px;">
                            <img :src="item.away_team_logo">
                            <h6 class="pt-2" style="font-weight: bold;">{{ item.away_team_name }}</h6>
                        </div>
                    </div>
                    <!-- <div class="text-center">
                        <b>{{ item.league_name }}</b>
                    </div> -->
                    <div class="layer">
                        <i class="fa-solid fa-circle-play text-white" style="font-size: 50px !important;"></i>
                        <h5 class="pt-1 text-white">
                            {{
                                $store.state.language === "en"
                                ? "Play"
                                : "Play"
                            }}
                        </h5>
                    </div>
                </div>
            </div>
            <!-- <div class="container d-flex justify-content-center mb-3">
                <div class="mr-2" style="width: 120px; height: 25px; border-radius: 10px; background: blue;">
                        <b class="text-white p-2">Coming Soon...</b>
                </div>
            </div>
            <div class="container">
                <div class="card work" v-for="item in itemsCart" :key="item.id">
                    <div class="d-flex justify-content-around pt-3">
                        <div class="d-flex flex-column align-items-center">
                            <img :src="item.firstImg">
                            <h4 class="pt-2" style="font-weight: bold;">{{ item.firstTeamName }}</h4>
                        </div>
                        <p class="text-white pt-1 my-auto" id="pTag">Vs</p>
                        <div class="d-flex flex-column align-items-center">
                            <img :src="item.secondImg">
                            <h4 class="pt-2" style="font-weight: bold;">{{ item.secondTeamName }}</h4>
                        </div>
                    </div>
                    <div class="text-center">
                        <b>{{ item.CountryName }}</b>
                    </div>
                    <div class="layer">
                        <i class="fa-solid fa-circle-play text-white" style="font-size: 50px !important;"></i>
                        <h5 class="pt-1 text-white">
                            {{
                                $store.state.language === "en"
                                ? "Play"
                                : "Play"
                            }}
                        </h5>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
  name: 'Live',
  data: () => ({
    itemsCart: [],
  }),
  methods: {
    async translateText(text, targetLanguage) {
      try {
        const translate = require('google-translate-api');

        const { text: translatedText } = await translate(text, { to: targetLanguage });
        console.log(`Original: ${text}`);
        console.log(`Translation: ${translatedText}`);
      } catch (error) {
        console.error('Error translating text:', error);
      }
    },
    async redirectRoute(path, query) {
      try {
        console.log('query', query)
        // if (path === 'tip') {
        //   const text = this.$store.state.language === 'en'
        //     ? 'This feature is not support yet.'
        //     : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
        //   Dialog.alert({
        //     message: text,
        //     confirmButtonText:
        //                     this.$store.state.language === 'en'
        //                       ? 'Okay'
        //                       : 'Okay',
        //   }).then(() => {
        //     // on close
        //   });
        // } else {
          this.$router.push({ path, query });
        // }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMatches() {
      try {
        const res = await axios.get('football-live-matches');
        this.itemsCart = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted(){
    this.fetchMatches()
  }
};
</script>

<style scoped>

.card {
    cursor: pointer;
    width: 100%;
    /* height: 170px; */
    border-radius: 10px !important;
}

img {
    width: 70px;
    height: 70px;
}

#pTag {
    width: 60px;
    height: 30px;
    background: #0473eb;
    font-weight: bolder;
    border-radius: 20px;
    text-align: center;
}

.layer{
    opacity: 0.9;
    width: 100%;
    height: 0;
    background: linear-gradient(#d7d2c9,#222222);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0  40px;
    text-align: center;
    font-size: 14px;
    transition: height 0.5s;
}

.work:hover img{
    transform: scale(1.1);
}
.work:hover .layer{
    height: 100%;
}

.live {
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes live-number-animation {
    from {
        background-color: red;
        color: white;
    }
    to {
      background-color: #fdd1da;
        color: red;
    }
}

.coming {
    animation-name: coming-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes coming-number-animation {
    from {
        background-color: blue;
        color: white;
    }
    to {
      background-color: #aaedf9;
        color: #03acca;
    }
}
</style>
