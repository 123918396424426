<template>
   <div class="container-fluid" style="user-select: none; background: linear-gradient(to top, rgb(142, 212, 101), rgb(0, 105, 7)); height: 100vh">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="logo mt-5 d-flex">
                    <img class="logo-img" src="/build/assets/img/brand/logo.png" style="width: 150px; height: 150px; margin-top: 4rem; margin-left: 4rem;">
                </div>
                <div class="left" style="margin: 0 0 0 5rem;">
                    <div class="p1">
                        <h1 class="heading-text text-dark" style="font-weight: bolder;">
                            The Best Football Betting Application in Myanmar
                        </h1>
                    </div>
                    <div class="p2">
                        <p class="text-white para-text mt-4">
                            You can watch football matches and bet freely with ease
                            from your android phone!
                        </p>
                    </div>
                    <div class="button pt-2">
                        <a href="https://api.myanmarsoccer.xyz/mms_v_1.0.1.apk" download>
                            <button class="btn btn-success" >
                                Download APK
                            </button>
                        </a>
                        <button class="btn btn-info ml-2"  @click="$router.push('/home')">
                            Play Online
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 d-flex right-img-container" style="padding-top: 10rem">
                <img src="/build/assets/img/theme/first.png" class="mt-5 rounded mx-auto my-auto img-fluid text-img">
                <img src="/build/assets/img/theme/second.png" class="mt-5 rounded mx-auto my-auto img-fluid phone-img">
                <img src="/build/assets/img/theme/third.png" class="mt-5 rounded mx-auto my-auto img-fluid text-img">
            </div>
        </div>
        <div class="row d-flex main-footer" style="margin-top: 16rem;">
            <p class="mx-auto text-dark text-sm" style="text-align: center; font-weight: bold">
                @ MyanmarSoccer 2022 Renamed <br>
                Privacy Teams FAQ
            </p>
        </div>
   </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>
    .text-img {
        width: 150px;
        height: 150px;
    }
    .phone-img {
        width: 140px;
        height: 270px;
    }
    h5{
        color: #fff;
    }
    .p1{
        font-size: 50px;
        color: #fff;
    }
    .img-cont {
        width: 30rem;
    }
    .heading-text {
        font-size: 32px !important;
    }
    .para-text {
        font-size: 18px !important;
    }

@media (max-width: 786px) {
        .text-img {
            width: 130px !important;
            height: 90px !important;
        }
        .phone-img {
            width: 100px !important;
            height: 180px !important;
        }
        .img-cont {
            width: 15rem;
        }
        .main-footer {
            margin-top: 0rem !important;
            padding-top: 3.5rem !important;
            /* padding-bottom: 1rem !important; */
        }
        .right-img-container {
            padding-top: 4rem !important;
        }
        .left {
                margin-left: 0.4rem !important;
        }
        .para-text {
            font-size: 16px !important;
            font-weight: normal !important;
        }
        .btn-md {
            padding: 4px 20px;
            font-size: 14px;
            margin-top: 1rem;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
        }
        .logo-img{
            margin: auto !important;
            width: 120px !important;
            height: 120px !important;
        }
        .logo{
            margin-top: 1rem !important;
        }
        .heading-text{
            font-size: 25px !important;
        }
}
</style>
