<template>
    <div>
      <h4 class="text-center mt-5 mb-3"><span class="text-success">{{footballLiveMatch.home_team_name}}</span> <small class="text-sm text-danger">VS</small> <span class="text-success">{{footballLiveMatch.away_team_name}}</span></h4>
      <div class="p-1">
        <VideoPlayer
            type="default"
            @pause="processPause"
            previewImageLink="poster.webp"
            :link="footballLink"
            :progress="30"
            :isMuted="false"
            :isControls="true"
            class="customClassName"
            style="width: 100%; height: 250px; border-radius: 5px; border: 1px solid black;"
        />
      </div>
      <div class="p-3 d-flex justify-content-center flex-wrap">
        <button class="col-6 btn btn-warning text-lg text-uppercase" style="margin-top: 1rem !important;" v-for="(link, index) in footballLiveMatch.servers" :key="index" @click="changeLink(link.link)">{{link.name}}</button>
      </div>
    </div>
</template>
<script>
import { VideoPlayer } from 'vue-hls-video-player';

export default {
  components: { VideoPlayer },
  data() {
    return {
      footballLiveMatch: {},
      footballLink: null,
    };
  },
  methods: {
    changeLink(link){
      this.footballLink = link
    },
    processPause(progress) {
      console.log(progress)
    },
    async fetchMatches() {
        try {
          const res = await axios.get('football-live-matches');
          this.footballLiveMatch = res.data.data[this.$route.query.index];
          this.footballLink = this.footballLiveMatch.servers[0]?.link;
        } catch (error) {
          console.log(error);
        }
      },
    },
  mounted() {
    this.fetchMatches()
  },
};
</script>