<template>
    <div v-if="$route.path !== '/auth/login'">
        <Sticky>
            <nav class="navbar navbar-horizontal navbar-expand-lg bg-dark">
                <div class="container-fluid">
                    <div class="navbar-brand text-white" :class="$route.path !== '/home' ? 'd-flex justify-content-between' : ''">
                        <div class="d-flex flex-column banking-icons my-auto ml-1 mr-3" v-if="$route.path !== '/auth/login'">
                            <i class="fas fa-chevron-left mx-auto my-1 text-xl" @click="$router.go(-1)" v-if="$route.path !== '/home'"></i>
                        </div>
                        <img src="/build/assets/img/theme/logo.jpg" alt="" @click="$router.push('/home')"/>
                        <!-- LOGO -->
                    </div>
                    <div class="d-flex" v-if="$route.path !== '/auth/login'">
                        <div
                            class="
                                text-white
                                mx-2
                                my-auto
                                d-flex
                                flex-column
                            "
                            @click="$router.go()"
                        >
                            <i class="fas fa-redo mx-auto"></i>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Refresh"
                                        : "Refresh"
                                }}
                            </div>
                        </div>
                        <div
                            class="
                                
                                text-white
                                mx-2
                                my-auto
                                d-flex
                                flex-column
                            "
                            @click="logout()"
                        >
                            <i class="ni ni-lock-circle-open mx-auto"></i>
                            <div>
                                {{
                                    $store.state.language === "en"
                                        ? "Log Out"
                                        : "ထွက်မည်"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </Sticky>
    </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Sticky },
  data() {
    return {
      imageLink: `https://ui-avatars.com/api/?name=${
        this.$store.state.authUser.email
      }&background=0D8ABC&color=fff`,
    };
  },
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      Dialog.confirm({
        title:
                    this.$store.state.language === 'en'
                      ? 'Are you sure to log out?'
                      : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
                    this.$store.state.language === 'en'
                      ? 'Cancel'
                      : 'မထွက်တော့ပါ',
        confirmButtonText:
                    this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en'
              ? 'Logged out!'
              : 'ထွက်ပြီးပြီ',
          );
        })
        .catch(() => {});
    },
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
};
</script>
<style scoped>
.navbar {
    padding: 3px;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 2px solid #FF5B22;
}
.navbar-horizontal .navbar-brand img {
    height: 50px;
}
.nav-link {
    cursor: pointer;
}
.navbar-brand.brand-text {
    font-size: 20px;
    color: red;
    margin-left: 0px;
    margin-right: 10px;
}
.navbar-brand {
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.dropdown-item {
    cursor: pointer;
}
.bg-default {
    background-color: #FF5B22 !important;
}
@media (max-width: 767.98px) {
    .navbar-brand {
        font-size: 14px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
</style>
