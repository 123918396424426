var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",staticStyle:{"user-select":"none","background":"linear-gradient(to top, rgb(142, 212, 101), rgb(0, 105, 7))","height":"100vh"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_vm._m(0),_c('div',{staticClass:"left",staticStyle:{"margin":"0 0 0 5rem"}},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"button pt-2"},[_vm._m(3),_c('button',{staticClass:"btn btn-info ml-2",on:{"click":function($event){return _vm.$router.push('/home')}}},[_vm._v(" Play Online ")])])])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo mt-5 d-flex"},[_c('img',{staticClass:"logo-img",staticStyle:{"width":"150px","height":"150px","margin-top":"4rem","margin-left":"4rem"},attrs:{"src":"/build/assets/img/brand/logo.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p1"},[_c('h1',{staticClass:"heading-text text-dark",staticStyle:{"font-weight":"bolder"}},[_vm._v(" The Best Football Betting Application in Myanmar ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2"},[_c('p',{staticClass:"text-white para-text mt-4"},[_vm._v(" You can watch football matches and bet freely with ease from your android phone! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://api.myanmarsoccer.xyz/mms_v_1.0.1.apk","download":""}},[_c('button',{staticClass:"btn btn-success"},[_vm._v(" Download APK ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-sm-12 d-flex right-img-container",staticStyle:{"padding-top":"10rem"}},[_c('img',{staticClass:"mt-5 rounded mx-auto my-auto img-fluid text-img",attrs:{"src":"/build/assets/img/theme/first.png"}}),_c('img',{staticClass:"mt-5 rounded mx-auto my-auto img-fluid phone-img",attrs:{"src":"/build/assets/img/theme/second.png"}}),_c('img',{staticClass:"mt-5 rounded mx-auto my-auto img-fluid text-img",attrs:{"src":"/build/assets/img/theme/third.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex main-footer",staticStyle:{"margin-top":"16rem"}},[_c('p',{staticClass:"mx-auto text-dark text-sm",staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v(" @ MyanmarSoccer 2022 Renamed "),_c('br'),_vm._v(" Privacy Teams FAQ ")])])
}]

export { render, staticRenderFns }